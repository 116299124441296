import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css'],
})
export class TermsOfUseComponent implements OnInit {
  public dataUser:any;
  public pageInfo: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
  ) { 
    this.dataUser  = localStorage.getItem('dataUser');
    this.dataUser = JSON.parse(this.dataUser);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.dataUser  = localStorage.getItem('dataUser');
    this.dataUser = JSON.parse(this.dataUser);
    this.loadInfos();
  }


  loadInfos() {
    this.httpService.get('terms-of-use', 'textos/8')
      .subscribe(response => {
        console.log(response);
        this.pageInfo = response;
      }, err => {
        alert('Não foi possível realizar a sua solicitação.');
      });
  }
}
