import { Component, OnInit } from "@angular/core";
import { HttpService } from "src/app/services/http.service";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnInit {
  public dataUser: any;
  public infos: Array<any> = [];
  public totalNotRead = 0;
  constructor(
    private httpService: HttpService,
    private utilService: UtilService
  ) {
    this.dataUser = localStorage.getItem("dataUser");
    this.dataUser = JSON.parse(this.dataUser);
  }

  ngOnInit() {
    this.utilService.setAnalytics('Notificações');
    window.scrollTo(0, 0);
    this.dataUser = localStorage.getItem("dataUser");
    this.dataUser = JSON.parse(this.dataUser);

    this.infos = [];
    this.loadInfos();
  }

  loadInfos() {
    this.httpService.get("getInfoUser", "usuarios").subscribe(
      (response) => {
        localStorage.setItem("dataUser", JSON.stringify(response));
        this.httpService.get("notificacoes", "notificacoes").subscribe(
          (response) => {
            this.infos = response.data;
            this.totalNotRead = response.totalNotRead;
          },
          (err) => {
            alert("Não foi possível realizar a sua solicitação.");
          }
        );
      },
      (err) => {
        alert("Não foi possível realizar o login.");
      }
    );
  }

  formatDate(date, type) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = data.getFullYear();
    if (type == "br") {
      return diaF + "-" + mesF + "-" + anoF;
    } else {
      return anoF + "-" + mesF + "-" + diaF;
    }
  }
}
