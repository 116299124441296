import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private loaderService: LoaderService,
  ) {


  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.route.paramMap.subscribe(params => {
      this.form = this.formBuilder.group({
        'hash': [this.route.snapshot.params.hash, [Validators.required]],
        'usu_senha': [null, [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/)
        ]],
        'usu_senha_confirmation': [null, [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/)
        ]],
      });
    });
  }

  onFormSubmit(form: NgForm) {
    this.httpService.post('auth/reset', 'auth/reset', this.form.value)
      .subscribe(response => {
        if (response == null) {
          this.loaderService.openModalAlert('Sucesso', 'A sua senha foi alterada com sucesso.', 'success');
          this.router.navigate(['/']);
        }

      }, err => {

      });

  }
}
