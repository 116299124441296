import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FinancialRoutingModule } from './financial-routing.module';
import { ListComponent } from './list/list.component';
import { ListViewComponent } from './list-view/list-view.component';
import { ViewComponent } from './view/view.component';
import { DetailComponent } from './detail/detail.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { AuthModule } from '../auth/auth.module';

@NgModule({
  declarations: [
    ListComponent,
    ListViewComponent,
    ViewComponent,
    DetailComponent,
    PaymentHistoryComponent,
  ],
  imports: [
    CommonModule,
    FinancialRoutingModule,
    AuthModule,
    ReactiveFormsModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class FinancialModule { }
