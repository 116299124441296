import { Injectable, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalAlertComponent } from '../components/modal-alert/modal-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSendMailComponent } from '../components/modal-send-mail/modal-send-mail.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isVisible = false;
  public isLoading = new BehaviorSubject(false);
 
  constructor(private modalService: NgbModal,) { }


  openModalAlert(title, message, status) {
    const modalRef = this.modalService.open(ModalAlertComponent);
    modalRef.componentInstance.modalTitle = title;
    modalRef.componentInstance.modalMessage = message; 
    modalRef.componentInstance.modalStatus = status;
  }

  openModalSendMail(slipID) {
    const modalRef = this.modalService.open(ModalSendMailComponent);
    modalRef.componentInstance.slipID = slipID;
  }
}
