import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from 'src/app/services/loader.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  public loading:any;
  public password_type:string = 'password';
  public form: FormGroup;
  public modalTitle: any;
  public modalMessage: any;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    localStorage.clear();
    this.form = this.formBuilder.group({
      'email': [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]]
    });
  }

  onFormSubmit(form: NgForm) {
    let dataRequest: any = form;
    dataRequest.origem = 'CAP WEB';
    dataRequest.email = dataRequest.email.toString().toLowerCase();
    
    this.httpService.post('modules/auth/request-reset', 'auth/request-reset', dataRequest)
      .subscribe(response => {
        if(response.mensagens.email) {
          this.loaderService.openModalAlert('Sucesso', 'Acesse o seu e-mail e clique no link enviado para redefinir a senha.', 'success');
          this.router.navigate(['/']);
        }
        
      }, err => {
        //alert("eerrsrasdrf");
      });
  }


  termosLink(link) {
    sessionStorage.setItem('linkVolta','/login')
    this.router.navigate([link]);
  }


  toggle(){
    console.log('click')
      this.password_type = this.password_type === 'text' ? 'password' : 'text';
  }


  private setToken(response) {
    localStorage.setItem('token', response.token);
    this.router.navigate(['/auth/inicial']);
    /**
     * this.authService.getInfo()
      .subscribe(response => {
        localStorage.setItem('userInfo', JSON.stringify(response.data));
      });
      this.router.navigate(['inicial']);
     */
  } 

}
