import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { ConfigsComponent } from './configs/configs.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { RecadastramentoComponent } from './recadastramento/recadastramento.component';
import { ServicesComponent } from './services/services.component';
import { TemplatePageComponent } from './template-page/template-page.component';
import { AlbumComponent } from './album/album.component';
import { GalleryComponent } from './gallery/gallery.component';
import { RenewalComponent } from './renewal/renewal.component';
import { HealthInfoComponent } from './health-info/health-info.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [ AuthGuardService ],
    component: HomeComponent
  },
  {
    path: 'dashboard',
    canActivate: [ AuthGuardService ],
    children: [
      { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
      { path: 'configs', component: ConfigsComponent, canActivate: [AuthGuardService] },
      { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuardService] },
      { path: 'formulario/inscricoes', component: RecadastramentoComponent, canActivate: [AuthGuardService] },
      { path: 'services', component: ServicesComponent },
      { path: 'page/:id', component: TemplatePageComponent, canActivate: [AuthGuardService] },
      { path: 'page/:page/album/:id', component: AlbumComponent, canActivate: [AuthGuardService] },
      { path: 'gallery', component: GalleryComponent, canActivate: [AuthGuardService] },
      { path: 'renewal-2022', component: RenewalComponent, canActivate: [AuthGuardService] },
      { path: 'gallery', component: GalleryComponent, canActivate: [AuthGuardService] },
      { path: 'health-info', component: HealthInfoComponent, canActivate: [AuthGuardService] }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
