import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  logout() {
    localStorage.setItem('isLogout', '1');
    localStorage.removeItem('token');
    localStorage.removeItem('localSlips');
    localStorage.removeItem('localSlipsVigente');
    localStorage.removeItem('localSlipsDetails');
    localStorage.removeItem('localSlipsSub');
    this.router.navigate(['/']);
  }

}
