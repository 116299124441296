import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeUrl, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from "src/environments/environment";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: 'app-template-page',
  templateUrl: './template-page.component.html',
  styleUrls: ['./template-page.component.css']
})
export class TemplatePageComponent implements OnInit {

  toggleTextPassword: boolean = false;

  onCheckboxChange(fieldName: string, option: any) {}

  page: any = {};
  form: FormGroup;
  Object = Object;
  staticUrl = environment.staticUrl;
  apiUrl = environment.apiUrl;

  private dataUser: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.dataUser = JSON.parse(localStorage.getItem("dataUser"));

    const id = this.route.snapshot.params.id;

    this.httpService.get("page", `paginas/${id}`).subscribe(
      (response) => {
        this.page = response;

        console.log(this.page);

        /*if (typeof this.page.galeria == "string") {
          this.page.galeria = JSON.parse(this.page.galeria);
        }*/

        if (typeof this.page.spotlight_content == "string") {
          this.page.spotlight_content = JSON.parse(this.page.spotlight_content);
        }

        if (typeof this.page.table_content == "string") {
          this.page.table_content = JSON.parse(this.page.table_content);
        }

        if (this.page.formulario.length > 0) {
          this.initForm();
        }
      },
      () => {
        this.router.navigate(["dashboard", "home"]);
      }
    );

  }

  getStaticUrl(path: string): SafeUrl {
    let url = "";

    if (!path.includes(environment.staticUrl)) {
      url = environment.staticUrl + path;
    } else {
      url = path;
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getResourceUrl(url: string): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getHtml(v: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(v);
  }

  onSubmit() {
    if (this.form.valid) {
      this.httpService.post('pagina', 'formulario', this.form.value).subscribe(() => {
        this.loaderService.openModalAlert('Sucesso', 'Dados enviados com sucesso.', 'success');
      });
    } else {
      this.loaderService.openModalAlert('Erro', 'Dados inválidos.', 'error');
    }
  }

  private initForm() {
    if (this.page.formulario.length > 0) {
      let group = {
        user_id: [this.dataUser.usu_id],
        form_id: [this.page.formulario[0].id]
      };

      const formulario = this.page.formulario[0];

      for (let index = 0; index < formulario.inputs.length; index++) {
        const input = formulario.inputs[index];
        const validators = [];

        input.rules.forEach((rule: string) => {
          const [ruleName, ruleValue] = rule.split(":");

          if (ruleName == "required") {
            validators.push(Validators.required);
          } else if (ruleName == "min") {
            validators.push(Validators.minLength(parseInt(ruleValue)));
          } else if (ruleName == "max") {
            validators.push(Validators.maxLength(parseInt(ruleValue)));
          }
        });

        group[input.name] = [null, validators];
      }

      this.form = this.formBuilder.group(group);
    }
  }


  searchImage() {

    const id = this.route.snapshot.params.id;
    console.log(id);
    const search = ((document.getElementById("search") as HTMLInputElement).value);

    this.httpService.get("page", `album/${id}?titulo=${search}`).subscribe(
      (response) => {
        this.page.album = response;
      },
      () => {
        this.router.navigate(["dashboard", "home"]);
      }
    );
  }

}
