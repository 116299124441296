import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css'],
})
export class ViewComponent implements OnInit {

  public dataSlip: any;
  public type:any;

  public infosDetail: Array<any> = [];
  public infosDetailTotal: any = 0;
  public slipOriginal = {
    nosso_numero: '',
    id_remessa_result_graf: '',
    periodo: '',
    vencimento: '',
    valor_boleto: '',
    valor_pg: '',
    a_pagar: '',
    acordo: '',
    pago: '',
    estorno: '',
    situacao_boleto: '',
    cd_boleto: '',
    link_boleto: '',
    boleto_crypt: '',
  }

  public months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez"
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private loaderService: LoaderService,
  ) {


  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.route.paramMap.subscribe(params => {
      this.dataSlip = JSON.parse(localStorage.getItem('slipInfo'));
      this.slipOriginal = JSON.parse(localStorage.getItem('slipOriginal'));
      
      this.type = this.route.snapshot.params.type;
      




      if (localStorage.getItem('localSlipsDetailsSub')) {
        let localSlipsDetailsSub: any = localStorage.getItem('localSlipsDetailsSub');
        this.infosDetail = JSON.parse(localSlipsDetailsSub);
        this.loadInfosSlip();
      } else {
        this.loadInfos(this.route.snapshot.params.type, this.route.snapshot.params.id);
      }
    });
  }

  openModalSendMail(slipID) {
    this.loaderService.openModalSendMail(slipID);
  }

  loadInfosSlip() {
    this.infosDetail.forEach(info => {
      this.infosDetailTotal = parseFloat(this.infosDetailTotal) + parseFloat(info.valor);
    });
  }


  loadInfos(type, id) {
    this.httpService.get('getMoviments', 'movimentos/' + type + '/' + id)
      .subscribe(response => {
        localStorage.removeItem('localSlipsDetailsSub');
        this.infosDetail = response;
        this.loadInfosSlip();
      }, err => {
        alert('Não foi retornar as informações.');
      });
  }


  isVisible(id_receita) {
    if (id_receita == 1) {
      return true;
    } else {
      return false;
    }
  }

  setSlip(slip, info?) {
    localStorage.setItem('slipInfo', JSON.stringify(slip));
    localStorage.setItem('infos', JSON.stringify(info));
  }


  public formatInfoPayment(info) {
    if (info == 'A_PAGAR') {
      return 'À pagar';
    } else if (info == 'ACORDO:') {
      return 'Acordo'
    } else if (info == 'QUITADO:') {
      return 'Quitado'
    } else if (info == 'ESTORNADO:') {
      return 'Estornado'
    } else {
      return 'Pago'
    }
  }

  public formtDateToPtBrWithHours(date) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF + ' às ' + data.getHours()  + ":" +  data.getMinutes();
  }


  public formtDateToPtBr(date) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  }

  public formtDateToPtBrMes(date) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    return this.months[data.getMonth()] + "/" + anoF;
  }



}
