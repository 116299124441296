import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent } from './list/list.component';
import { ListViewComponent } from './list-view/list-view.component';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { ViewComponent } from './view/view.component';
import { DetailComponent } from './detail/detail.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';

const routes: Routes = [
  {
    path: 'financial',
    children: [
      { path: 'list', component: ListComponent },
      { path: 'list-view/:slipNumber', component: ListViewComponent },
      { path: 'view/:type/:id', component: ViewComponent },
      { path: 'detail/:type', component: DetailComponent },
      { path: 'payment-history', component: PaymentHistoryComponent },
    ],
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinancialRoutingModule { }
