import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class HttpService {
  loading;

  constructor(private http: HttpClient) {}

  get(componet, router): Observable<any> {
    return this.http.get<any>(environment.apiUrl + router).pipe(
      tap((_) => this.log(componet)),
      catchError(this.handleError)
    );
  }

  post(componet, router, parameters?): Observable<any> {
    return this.http.post<any>(environment.apiUrl + router, parameters).pipe(
      tap((_) => this.log(componet)),
      catchError(this.handleError)
    );
  }

  put(componet, router, parameters?): Observable<any> {
    return this.http.put<any>(environment.apiUrl + router, parameters).pipe(
      tap((_) => this.log(componet)),
      catchError(this.handleError)
    );
  }

  delete(componet, router): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + router).pipe(
      tap((_) => this.log(componet)),
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : "Server error";
    return Observable.throw(error);
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {}
}
