import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { HttpService } from "src/app/services/http.service";
import { LoaderService } from "src/app/services/loader.service";
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  public password_type: string = "password";
  public loading: any;
  public form: FormGroup;
  public dateOriginal: any;
  public isActiveToggleTextPassword: Boolean = true;
  public isActiveToggleTextPasswordConfirmation: Boolean = true;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.utilService.setAnalytics('Usuário (Cadastro)');
    window.scrollTo(0, 0);
    localStorage.clear();
    this.form = this.formBuilder.group({
      usu_id_pessoa: [null, []],     
      usu_cpf: [null, [Validators.required]],
      usu_titulo: [null, [Validators.required]],
      /*
      usu_nome: [null, [Validators.required]],
      usu_login: [null, [Validators.required]],
      usu_senha: [null, [Validators.required]],
      usu_senha_confirmation: [null, [Validators.required]],
      usu_email: [
        null,
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
        ],
      ],
      usu_dt_nascimento: [null, [Validators.required]],*/
    });
  }

  toggle() {
    console.log("click");
    this.password_type = this.password_type === "text" ? "password" : "text";
  }

  onDateSelect(event) {
    this.dateOriginal = event.day + "/" + event.month + "" + event.year;
    //this.form.get('usu_dt_nascimento').setValue(event.year+'-'+event.month+'-'+event.day);
    return;
  }

  updateDate(event) {
    this.form
      .get("usu_dt_nascimento")
      .setValue(event.year + "-" + event.month + "-" + event.day);
    return;
  }

  public toggleTextPassword(): void {
    this.isActiveToggleTextPassword =
      this.isActiveToggleTextPassword == true ? false : true;
  }
  public getType() {
    return this.isActiveToggleTextPassword ? "password" : "text";
  }

  public toggleTextPasswordConfirmation(): void {
    this.isActiveToggleTextPasswordConfirmation =
      this.isActiveToggleTextPasswordConfirmation == true ? false : true;
  }
  public getTypeConfirmation() {
    return this.isActiveToggleTextPasswordConfirmation ? "password" : "text";
  }

  onFormSubmit(form: NgForm) {
    let dataRequest: any = form;
    dataRequest.origem = 'CAP WEB';

    this.httpService.post('modules/auth/request-reset', 'auth/request-reset', dataRequest)
    .subscribe(response => {
      if(response.mensagens.email) {
        this.loaderService.openModalAlert('E-mail enviado!', 'Seu e-mail cadastrado é:'+'<br><br>'+response.mensagens.email+'<br><br>'+'Dúvidas, entrar em contato com a Central de Atendimento, 2ª a 6ª feira, 8h30 às 18h, ou pelo telefone: (11) 3065-2020 e e-mail: atendimento@paulistano.org.br', 'success');
        this.router.navigate(['/']);
      }
    }, err => {
      console.log("Não foi possível recuperar o cadastrado!");
    });
  }

  private removeMaskToCPF(ObjCPF) {
    return ObjCPF.replace(/\D/g, "");
  }

  private formatDate(date, type) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = data.getFullYear();
    if (type == "br") {
      return diaF + "-" + mesF + "-" + anoF;
    } else {
      return anoF + "-" + mesF + "-" + diaF;
    }
  }
}
