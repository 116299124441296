import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from "src/environments/environment";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.css']
})
export class AlbumComponent implements OnInit {

  album: any = {};
  form: FormGroup;
  Object = Object;
  staticUrl: string;
  apiUrl = environment.apiUrl;

  private dataUser: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
    private domSanitizer: DomSanitizer
    ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.staticUrl = environment.staticUrl;
    
    this.dataUser = JSON.parse(localStorage.getItem("dataUser"));

    const id = this.route.snapshot.params.id;
    const id_page = this.route.snapshot.params.page;


    this.httpService.get("page", `album/${id}/fotos?page=1`).subscribe(
      (response) => {
        this.album = response;
      },
      () => {
        this.router.navigate(["dashboard", "home"]);
      }
      );

  }

  getStaticUrl(path: string): SafeUrl {
    let url = "";

    if (!path.includes(environment.staticUrl)) {
      url = environment.staticUrl + path;
    } else {
      url = path;
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getResourceUrl(url: string): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onSubmit(){

    var retorno = [];
    
    if(true){
        this.album.fotos.data.forEach(function (value, key) {
          if(value.checked){
            retorno.push("id[]="+value.id);
          }
        });
    } else {
        const id = this.route.snapshot.params.id;
        retorno.push("album="+id);
    }
    
     window.location.href = environment.apiUrl.replace("/api/","/")+'album/download?'+retorno.join("&");
     
  }

  CheckAllOptions() {    
    if (this.album.fotos.data.every(val => val.checked == true))
      this.album.fotos.data.forEach(val => { val.checked = false });
    else
      this.album.fotos.data.forEach(val => { val.checked = true });
  }
  
  public setPage(page: number) {
      const id = this.route.snapshot.params.id;
      
      this.httpService.get("page", `album/${id}/fotos?page=${page}`).subscribe(
      (response) => {
        this.album = response;
      });
  }
  
  counter(i: number) {
    return new Array(i);
  }

}
