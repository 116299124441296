import { Injectable } from "@angular/core";
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor(private firebaseAnalytics: AngularFireAnalytics) {}

  public setAnalytics(pageCurrent) {
    this.firebaseAnalytics
      .setCurrentScreen(pageCurrent)
      .then((res: any) => {
        console.log('PAGE -->', pageCurrent)
        console.log(res)
      })
      .catch((error: any) => {});
  }
}
