import { Component, OnInit } from "@angular/core";
import { HttpService } from "src/app/services/http.service";
declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  pages: any[] = [];
  public dataUser: any;

  constructor(private httpService: HttpService) {
    this.dataUser = localStorage.getItem('dataUser');
    this.dataUser = JSON.parse(this.dataUser);
  }

  ngOnInit() {


    this.httpService.get("inicial", "paginas").subscribe((pages) => {
      this.pages = pages;
    }, () => { });
  }

  openNav() {
    $(this).toggleClass("active");
    $(this).find(".menu-trigger").toggleClass("active");
    $(".block-menu").find(".wrap").toggleClass("active");
    $(".block-menu").toggleClass("menu-open");
    $("body").toggleClass("not-scroll");
  }

  showare(): void {
    this.httpService.post("showare", `showare/login`).subscribe(
      (response) => {
        window.open(response.url, "_blank");
      },
      () => {

      }
    );
  }

}
