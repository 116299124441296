import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoaderService } from "src/app/services/loader.service";
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.css"],
})
export class DetailComponent implements OnInit {
  public dataSlip: any;
  public type: any;
  public modulePage: any;
  public slipOriginal = {
    nosso_numero: "",
    id_remessa_result_graf: "",
    periodo: "",
    vencimento: "",
    valor_boleto: "",
    valor_pg: "",
    a_pagar: "",
    acordo: "",
    pago: "",
    estorno: "",
    situacao_boleto: "",
    cd_boleto: "",
    link_boleto: "",
    boleto_crypt: "",
  };
  public slipInfo: any;

  public months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  constructor(
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.utilService.setAnalytics('Boletos (Lançamentos)');
    window.scrollTo(0, 0);
    this.route.paramMap.subscribe((params) => {
      this.dataSlip = JSON.parse(localStorage.getItem("slipInfo"));
      this.slipInfo = JSON.parse(localStorage.getItem("infos"));
      this.slipOriginal = JSON.parse(localStorage.getItem("slipOriginal"));

      this.type = this.route.snapshot.params.type;

      if (this.type == "garagem") {
        this.modulePage = "ESTACIONAMENTO";
      } else if (this.type == "convites") {
        this.modulePage = "CONVITES";
      } else if (this.type == "sala-jovem") {
        this.modulePage = "SALA JOVEM";
      } else if (this.type == "armario") {
        this.modulePage = "ARMÁRIOS";
      } else if (this.type == "carteirinha-provisoria") {
        this.modulePage = "carteirinha-provisoria";
      } else if (this.type == "contribuicao-acompanhante") {
        this.modulePage = "CONTRIBUIÇÃO ACOMPANHANTE";
      } else if (this.type == "cursos") {
        this.modulePage = "CURSOS";
      }
    });
  }

  openModalSendMail(slipID) {
    this.loaderService.openModalSendMail(slipID);
  }

  isVisible(id_receita) {
    if (id_receita == 1) {
      return true;
    } else {
      return false;
    }
  }

  setSlip(slip) {
    localStorage.setItem("slipInfo", JSON.stringify(slip));
  }

  public overnightStay(info) {
    if (info == "N") {
      return "Não";
    } else {
      return "Sim";
    }
  }

  public formtDateToPtBrWithHours(date) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = data.getFullYear();
    return (
      diaF +
      "/" +
      mesF +
      "/" +
      anoF +
      " às " +
      data.getHours() +
      ":" +
      data.getMinutes()
    );
  }

  public formatInfoPayment(info) {
    if (info == "A_PAGAR") {
      return "À pagar";
    } else if (info == "ACORDO:") {
      return "Acordo";
    } else if (info == "QUITADO:") {
      return "Quitado";
    } else if (info == "ESTORNADO:") {
      return "Estornado";
    } else {
      return "Pago";
    }
  }

  public formtDateToPtBr(date) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  }

  public formtDateToPtBrMes(date) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = data.getFullYear();
    return this.months[data.getMonth()] + "/" + anoF;
  }
}
