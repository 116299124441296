import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { UpdateComponent } from './update/update.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  {
    path: 'auth',
    children: [
      { path: 'login-admin/:hash', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'update', component: UpdateComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'change-password/:hash', component: ChangePasswordComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
