import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

import localePT from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePT, 'pt', localeExtraPT);


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';


//components
import { LoaderComponent } from './components/loader/loader.component';
import { ModalAlertComponent } from './components/modal-alert/modal-alert.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { AuthModule } from './modules/auth/auth.module';
import { PagesModule } from './modules/pages/pages.module';
import { AuthGuardService } from './guards/auth-guard.service';
import { LoaderService } from './services/loader.service';
import { FinancialModule } from './modules/financial/financial.module';
import { ModalSendMailComponent } from './components/modal-send-mail/modal-send-mail.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { ScssSampleComponent } from './scss-sample/scss-sample.component';


@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    ModalAlertComponent,
    ModalSendMailComponent,
    ScssSampleComponent,
  ],
  entryComponents: [ModalAlertComponent, ModalSendMailComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    NgbModule,
    FormsModule,
    AuthModule,
    DashboardModule,
    PagesModule,
    FinancialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    RecaptchaV3Module
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    NgbModule,
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    AuthGuardService,
    LoaderService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.v3SiteKey
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
