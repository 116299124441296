import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from '../../components/header/header.component';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { AppModule } from 'src/app/app.module';
import { ConfigsComponent } from './configs/configs.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AuthModule } from '../auth/auth.module';
import { ServicesComponent } from './services/services.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrMaskerModule } from 'br-mask';
import { TemplatePageComponent } from './template-page/template-page.component';
import { GalleryComponent } from './gallery/gallery.component';
import { RenewalComponent } from './renewal/renewal.component';
import { RecadastramentoComponent } from './recadastramento/recadastramento.component';
import { AlbumComponent } from './album/album.component';
import { HealthInfoComponent } from './health-info/health-info.component';


@NgModule({
  declarations: [
    HomeComponent,
    ConfigsComponent,
    NotificationsComponent,
    ServicesComponent,
    TemplatePageComponent,
    GalleryComponent,
    RenewalComponent,
    RecadastramentoComponent,
    AlbumComponent,
    HealthInfoComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    BrMaskerModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class DashboardModule { }
