import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { HttpService } from "src/app/services/http.service";
import { DomSanitizer } from "@angular/platform-browser";
import { LoaderService } from "src/app/services/loader.service";
import { UtilService } from 'src/app/services/util.service';
import { OnExecuteData, OnExecuteErrorData, ReCaptchaV3Service } from "ng-recaptcha";
import { Subscription } from "rxjs";
declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public loading: any;
  public password_type: string = "password";
  public form: FormGroup;
  private return: string = '';
  public token: string = '';
  public isActiveToggleTextPassword: Boolean = true;

  public recentToken = "";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public recentError?: { error: any };

  private allExecutionsSubscription: Subscription;
  private allExecutionErrorsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private recaptchaService: ReCaptchaV3Service
  ) {
    localStorage.removeItem("token");
  }

  public executeRecaptcha(action: string): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaService.execute(action).subscribe(
      (token) => {
        this.form.patchValue({
          recaptchaToken: token
        })
        this.recentError = undefined;
      },
      (error) => {
        this.recentToken = "";
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.recentError = { error };
      },
    );
  }

  ngOnInit() {
    if (localStorage.getItem('isLogout')) {
      localStorage.removeItem('isLogout');
      this.refresh();
    }
    this.utilService.setAnalytics('Login');
    window.scrollTo(0, 0);
    localStorage.removeItem("localSlips");
    localStorage.removeItem('localSlipsVigente');
    localStorage.removeItem("localSlipsDetails");
    localStorage.removeItem("localSlipsSub");
    localStorage.removeItem("token");

    if (this.route.snapshot.params.hash) {
      this.setTokenAdmin(this.route.snapshot.params.hash);
    }

    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/dashboard/home');

    this.form = this.formBuilder.group({
      login: [null, [Validators.required]],
      password: [null, [Validators.required]],
      recaptchaToken: ["", [Validators.required]],
      remember: [false],
    });

    let isRemember = localStorage.getItem("isRemember");
    let isRememberLogin = localStorage.getItem("isRememberLogin");
    if (isRemember) {
      this.form.get("remember").setValue(true);
      this.form.get("login").setValue(isRememberLogin);
      document.getElementById("remember").classList.add("selected");
    }

    // - recaptcha
    this.executeRecaptcha('login');
  }

  public ngOnDestroy(): void {
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.allExecutionErrorsSubscription) {
      this.allExecutionErrorsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }

  refresh(): void {
    window.location.reload();
  }

  public toggleTextPassword(): void {
    this.isActiveToggleTextPassword =
      this.isActiveToggleTextPassword == true ? false : true;
  }
  public getType() {
    return this.isActiveToggleTextPassword ? "password" : "text";
  }

  changeRemember() {
    // document.getElementById("remember").classList.remove("selected");
    if (!this.form.value.remember) {
      this.form.get("remember").setValue(true);
      document.getElementById("remember").classList.add("selected");
    } else {
      this.form.get("remember").setValue(false);
      document.getElementById("remember").classList.remove("selected");
    }

    /**
     * $('.form-custom .checkbox label input').click(function () {
      $(this).parent().toggleClass('selected');
    });
     */
  }

  onFormSubmit(form: NgForm) {
    let dataRequest: any = form;
    dataRequest.origem = "CAP WEB";
    dataRequest.cookie = this.getCookie('__Secure-Spvll');
    dataRequest.login = dataRequest.login.toString().toLowerCase();

    if (dataRequest.remember) {
      localStorage.setItem("isRemember", "true");
      localStorage.setItem("isRememberLogin", dataRequest.login);
    } else {
      localStorage.removeItem("isRemember");
      localStorage.removeItem("isRememberLogin");
    }

    this.httpService
      .post("modules/auth/login", "auth/login", dataRequest)
      .subscribe(
        (response) => {
          if (response.status == "sucesso") {
            localStorage.setItem('isLogin', '1');
            localStorage.setItem("coduser", response.usu_id);
            this.setToken(response);
          } else {
          }
        },
        (error) => { }
      );
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  termosLink(link) {
    sessionStorage.setItem("linkVolta", "/login");
    this.router.navigate([link]);
  }

  toggle() {
    console.log("click");
    this.password_type = this.password_type === "text" ? "password" : "text";
  }

  public setToken(response) {
    localStorage.setItem("token", response.token);
    let id = localStorage.getItem("coduser");
    this.httpService.get("getInfoUser", "usuarios/myaccount").subscribe(
      (response) => {
        localStorage.setItem("dataUser", JSON.stringify(response));
        this.router.navigateByUrl(this.return);
      },
      (err) => {
        //alert('Não foi possível realizar o login.');
      }
    );
    /**
     * this.authService.getInfo()
      .subscribe(response => {
        localStorage.setItem('userInfo', JSON.stringify(response.data));
      });
      this.router.navigate(['inicial']);
     */
  }

  public setTokenAdmin(token) {
    localStorage.setItem("token", token);
    let id = localStorage.getItem("coduser");

    this.httpService.get("getInfoUser", "usuarios/myaccount").subscribe(
      (response) => {
        localStorage.setItem("dataUser", JSON.stringify(response));
        localStorage.setItem("coduser", response.id);
        this.router.navigate(["dashboard/home"]);
      },
      (err) => {
        //alert('Não foi possível realizar o login.');
      }
    );
    /**
     * this.authService.getInfo()
      .subscribe(response => {
        localStorage.setItem('userInfo', JSON.stringify(response.data));
      });
      this.router.navigate(['inicial']);
     */
  }
}
