import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { UpdateComponent } from './update/update.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrMaskerModule } from 'br-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FormAdesaoBoletoOnlineComponent } from 'src/app/components/form-adesao-boleto-online/form-adesao-boleto-online.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LoginComponent,
    RegisterComponent,
    UpdateComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    FormAdesaoBoletoOnlineComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrMaskerModule,
    NgbModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    FormAdesaoBoletoOnlineComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-br' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuthModule { }
