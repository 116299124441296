import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';


@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.css'],
})
export class ListViewComponent implements OnInit {

  public dataSlip: any;
  public slipInfo: Array<any> = [];
  public slipOriginal = {
    nosso_numero: '',
    id_remessa_result_graf: '',
    periodo: '',
    vencimento: '',
    valor_boleto: '',
    valor_pg: '',
    a_pagar: '',
    acordo: '',
    pago: '',
    estorno: '',
    situacao_boleto: '',
    cd_boleto: '',
    link_boleto: '',
    boleto_crypt: '',
  }
  public infosUnionContribution: Array<any> = [];
  public infosUnionContributionTotal: any = 0;
  public infosSlip: Array<any> = [];
  public infosRoomMovement: Array<any> = [];
  public infosRoomMovementTotal: any = 0;
  public infosGarage: Array<any> = [];
  public infosGarageTotal: any = 0;
  public infosProvisionalCard: Array<any> = [];
  public infosProvisionalCardTotal: any = 0;
  public infosCourses: Array<any> = [];
  public infosCoursesTotal: any = 0;
  public infosCabinets: Array<any> = [];
  public infosCabinetsTotal: any = 0;
  public infosProvisionalWallet: Array<any> = [];
  public infosProvisionalWalletTotal: any = 0;
  public infosAccompanimentContribution: Array<any> = [];
  public infosAccompanimentContributionTotal: any = 0;
  public infosMedicalServices: Array<any> = [];
  public infosMedicalServicesTotal: any = 0;
  public infosRecantoInfantil: Array<any> = [];
  public infosRecantoInfantilTotal: any = 0;



  public infosLibrary: Array<any> = [];
  public infosLibraryTotal: any = 0;
  public infosInvites: Array<any> = [];
  public infosInvitesTotal: any = 0;
  public months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez"
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private loaderService: LoaderService,
  ) {


  }

  ngOnInit() {
    localStorage.removeItem('localSlipsDetailsSub');
    window.scrollTo(0, 0);
    this.route.paramMap.subscribe(params => {
      this.dataSlip = JSON.parse(localStorage.getItem('slipInfo'));
      this.slipOriginal = JSON.parse(localStorage.getItem('slipOriginal'));
      this.loadInfos(this.route.snapshot.params.slipNumber);
    });
  }

  openModalSendMail(slipID) {
    this.loaderService.openModalSendMail(slipID);
  }


  loadInfos(slipNumber) {
    this.slipInfo = [];
    this.infosUnionContribution = [];
    this.infosRoomMovement = [];
    this.infosGarage = [];
    this.infosProvisionalCard = [];
    this.infosCourses = [];
    this.infosCabinets = [];
    this.infosProvisionalWallet = [];
    this.infosAccompanimentContribution = [];
    this.infosMedicalServices = [];

    this.infosGarageTotal = 0;
    this.infosCoursesTotal = 0;
    this.infosInvitesTotal = 0;
    this.infosLibraryTotal = 0;
    this.infosCabinetsTotal = 0;
    this.infosRoomMovementTotal = 0;
    this.infosUnionContributionTotal = 0;
    this.infosProvisionalCardTotal = 0;
    this.infosProvisionalWalletTotal = 0;
    this.infosAccompanimentContributionTotal = 0;
    this.infosMedicalServicesTotal = 0;


    if (localStorage.getItem('localSlipsDetails')) {
      let localSlipsDetails: any = localStorage.getItem('localSlipsDetails');
      this.slipInfo = JSON.parse(localSlipsDetails);
      this.loadInfosSlips();
    } else {
      this.loadSlipsDetails(slipNumber);
    }
  }

  loadSlipsDetails(slipNumber) {
    this.httpService.get('getMoviments', 'boletos/' + slipNumber + '/compromissos')
      .subscribe(response => {
        localStorage.setItem('localSlipsDetails', JSON.stringify(response));
        this.slipInfo = response;

        this.loadInfosSlips();
      }, err => {
        alert('Não foi retornar as informações.');
      });
  }

  loadInfosSlips() {
    this.slipInfo.forEach(info => {
      if (info.id_receita == 1) {//CONTRIB. SOCIAL
        this.infosUnionContribution.push(info);
        this.infosUnionContributionTotal = parseFloat(this.infosUnionContributionTotal) + parseFloat(info.valor);
      } else if (info.receita == 'ESTACIONAMENTO') {
        this.infosGarage.push(info);
        this.infosGarageTotal = parseFloat(this.infosGarageTotal) + parseFloat(info.valor);
      } else if (info.receita == 'CONVITES') {
        this.infosInvites.push(info);
        this.infosInvitesTotal = parseFloat(this.infosInvitesTotal) + parseFloat(info.valor);
      } else if (info.receita == 'SALA JOVEM') {
        this.infosRoomMovement.push(info);
        this.infosRoomMovementTotal = parseFloat(this.infosRoomMovementTotal) + parseFloat(info.valor);
      } else if (info.id_receita == 4 || info.id_receita == 11) {//MENS.CURSOS
        this.infosCourses.push(info);
        this.infosCoursesTotal = parseFloat(this.infosCoursesTotal) + parseFloat(info.valor);
      } else if (info.receita == 'ARMARIOS') {
        this.infosCabinets.push(info);
        this.infosCabinetsTotal = parseFloat(this.infosCabinetsTotal) + parseFloat(info.valor);
      } else if (info.receita == 'BIBLIOTECA') {
        this.infosLibrary.push(info);
        this.infosLibraryTotal = parseFloat(this.infosLibraryTotal) + parseFloat(info.valor);
      } else if (info.id_receita == 142) {//CARTEIRA PROVISORIA
        this.infosProvisionalWallet.push(info);
        this.infosProvisionalWalletTotal = parseFloat(this.infosAccompanimentContributionTotal) + parseFloat(info.valor);
      } else if (info.id_receita == 175) {//CONTRIBUICAO ACOMPANHANTE
        this.infosAccompanimentContribution.push(info);
        this.infosAccompanimentContributionTotal = parseFloat(this.infosAccompanimentContributionTotal) + parseFloat(info.valor);
      } else if (info.id_receita == 192) {//SERVICOS MEDICOS
        this.infosMedicalServices.push(info);
        this.infosMedicalServicesTotal = parseFloat(this.infosMedicalServicesTotal) + parseFloat(info.valor);
      } else if (info.id_receita == 3 || info.id_receita == 140) {//RECANTO INFANTIL
        this.infosRecantoInfantil.push(info);
        this.infosRecantoInfantilTotal = parseFloat(this.infosRecantoInfantilTotal) + parseFloat(info.valor);
      }
    });
  }


  isVisible(id_receita) {
    if (id_receita == 1) {
      return true;
    } else {
      return false;
    }
  }

  setSlip(slip) {
    localStorage.setItem('slipInfo', JSON.stringify(slip));
  }


  public formatInfoPayment(info) {
    if (info == 'A_PAGAR') {
      return 'À pagar';
    } else if (info == 'ACORDO:') {
      return 'Acordo'
    } else if (info == 'QUITADO:') {
      return 'Quitado'
    } else if (info == 'ESTORNADO:') {
      return 'Estornado'
    } else {
      return 'Pago'
    }
  }


  public formtDateToPtBr(date) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  }

  public formtDateToPtBrMes(date) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    return this.months[data.getMonth()] + "/" + anoF;
  }



}
