import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { LoaderService } from "../services/loader.service";
import { ModalAlertComponent } from "../components/modal-alert/modal-alert.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private modalService: NgbModal
  ) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //set loading
    if (!request.url.includes('api/boletos?')) {
      this.loaderService.isLoading.next(true);
    }

    if (request.url.includes("/api/")) {

      request = request.clone({
        withCredentials: true,
      });

      /* const token = localStorage.getItem("token");
      if (token) {
        request = request.clone({
          setHeaders: {
            "Api-Token": token,
          },
        });
      } */

      if (!request.headers.has("Content-Type")) {
        request = request.clone({
          setHeaders: {
            "content-type": "application/json",
          },
        });
      }

    }

    request = request.clone({
    headers: request.headers.set("Accept", "application/json"),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log("event--->>>", event);
          this.loaderService.isLoading.next(this.requests.length > 0);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (error.error.status) {
            this.loaderService.openModalAlert(
              "Atenção",
              "Login ou senha inválido.",
              "error"
            );
            this.router.navigate(["/"]);
          } else {
            this.loaderService.openModalAlert(
              "Atenção",
              "Sua sessão expirou.",
              "error"
            );
            this.router.navigate(["/"]);
          }
        } else if (error.status === 412) {
          let msgs = "";
          msgs +=
            typeof error.error.mensagens.usu_cpf != "undefined"
              ? error.error.mensagens.usu_cpf + "<br>"
              : "";
          msgs +=
            typeof error.error.mensagens.usu_id_pessoa != "undefined"
              ? error.error.mensagens.usu_id_pessoa + "<br>"
              : "";
          msgs +=
            typeof error.error.mensagens.usu_nome != "undefined"
              ? error.error.mensagens.usu_nome + "<br>"
              : "";
          msgs +=
            typeof error.error.mensagens.usu_titulo != "undefined"
              ? "Associado não encontrado." + "<br>"
              : "";
          msgs +=
            typeof error.error.mensagens.usu_login != "undefined"
              ? error.error.mensagens.usu_login + "<br>"
              : "";
          msgs +=
            typeof error.error.mensagens.usu_senha != "undefined"
              ? error.error.mensagens.usu_senha + "<br>"
              : "";
          msgs +=
            typeof error.error.mensagens.usu_email != "undefined"
              ? error.error.mensagens.usu_email + "<br>"
              : "";
          msgs +=
            typeof error.error.mensagens.usu_dt_nascimento != "undefined"
              ? error.error.mensagens.usu_dt_nascimento + "<br>"
              : "";
          msgs +=
            typeof error.error.mensagens.hash != "undefined"
              ? "Hash inválido. Por favor, solicite uma nova senha." + "<br>"
              : "";
          if (msgs != "") {
            this.loaderService.openModalAlert("Atenção", msgs, "error");
          }
        } else if (error.status === 422) {
          let msgs = "";
          msgs +=
            typeof error.error.usu_senha != "undefined"
              ? "As senhas não são iguais." + "<br>"
              : "";
          if (msgs != "") {
            this.loaderService.openModalAlert("Atenção", msgs, "error");
          }
        } else if (error.status === 404) {
          let msgs = "";
          msgs +=
            typeof error.error.mensagens.status != "undefined"
              ? error.error.mensagens.status + "<br>"
              : "";
          msgs +=
            typeof error.error.mensagens.email != "undefined"
              ? error.error.mensagens.email + "<br>"
              : "";
          if (msgs != "") {
            if(msgs == "Dados incorretos<br>"){
                msgs = "<b>Dados incorretos!</b><br><br>Dúvidas, entrar em contato com a Central de Atendimento, 2ª a 6ª feira, 8h30 às 18h, ou pelo telefone: (11) 3065-2020 e e-mail: atendimento@paulistano.org.br";
            }
            this.loaderService.openModalAlert("Atenção", msgs, "error");
          }
        } else if (error.status === 500) {
          this.loaderService.openModalAlert(
            "Atenção",
            "Não foi possível realizar a sua solicitação.",
            "error"
          );
        } else {
          this.loaderService.openModalAlert(
            "Atenção",
            "Não foi possível realizar a sua solicitação.",
            "error"
          );
        }

        this.loaderService.isLoading.next(false);
        return throwError(error);
      })
    );
  }
}
