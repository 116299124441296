import { Component, OnInit } from "@angular/core";
import { HttpService } from "src/app/services/http.service";
import { UtilService } from "src/app/services/util.service";


@Component({
  selector: 'app-recadastramento',
  templateUrl: './recadastramento.component.html',
  styleUrls: ['./recadastramento.component.css']
})
export class RecadastramentoComponent implements OnInit {

  public dataUser: any;
  public infos: Array<any> = [];
  public totalNotRead = 0;
  constructor(
    private httpService: HttpService,
    private utilService: UtilService
  ) {
    this.dataUser = localStorage.getItem("dataUser");
    this.dataUser = JSON.parse(this.dataUser);
  }

  ngOnInit() {
    this.utilService.setAnalytics('Recadastramento');
    window.scrollTo(0, 0);
    this.dataUser = localStorage.getItem("dataUser");
    this.dataUser = JSON.parse(this.dataUser);

    this.infos = [];
  }

}
