import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolityComponent } from './privacy-polity/privacy-polity.component';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'pages',
    children: [
      { path: 'terms-of-use', component: TermsOfUseComponent },
      { path: 'privacy-polity', component: PrivacyPolityComponent }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
