import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.css']
})

export class ModalAlertComponent implements OnInit {

  @Input() modalTitle;
  @Input() modalMessage;
  @Input() modalStatus;
  public button: any;
  
 
  constructor(public activeModal: NgbActiveModal) {}
 
  ngOnInit() {
    if(this.modalStatus == 'error') {
      this.button = 'red';
    }else{
      this.button = 'green';
    }
  }

  showModal():void {
    $("#modalMail").modal('show');
  }

  sendModal(): void {
    //do something here
    this.hideModal();
  }
  hideModal():void {
    document.getElementById('close-modal').click();
  }



}
